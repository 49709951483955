import { Group, Paper, Select } from '@mantine/core';
import { useFragment } from '@apollo/client';
import { ReactNode } from 'react';
import {
  WaterStationFilterPanelFragment,
  WaterStationFilterPanelFragmentDoc,
} from '@/graphql';
import { useWaterStationFilterSettings } from '@/components/WaterStations/WaterStationFilterSettingsContext';

type FilterProps = {
  title?: ReactNode;
};

export const WaterStationFilterPanel = ({ title }: FilterProps) => {
  const { setGrouping, grouping } = useWaterStationFilterSettings();
  const { data: query } = useFragment<WaterStationFilterPanelFragment>({
    fragment: WaterStationFilterPanelFragmentDoc,
    from: {
      __typename: 'Query',
    },
  });

  return (
    <Paper p={'md'} shadow={'md'}>
      <Group position={'apart'}>
        {title && <>{title}</>}
        <Select
          clearable
          placeholder={'No Grouping'}
          defaultValue={grouping}
          onChange={(value) => setGrouping(value)}
          data={
            (query.knownTags ?? []).map((d) => ({
              value: d?.key as string,
              label: d?.key as string,
            })) ?? []
          }
        />
      </Group>
    </Paper>
  );
};
