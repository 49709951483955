'use client';
import { Flex, Title } from '@mantine/core';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { WaterStationGroupTable } from '@/components/WaterStations/WaterStationGroupTable';
import {
  useWaterStationDataTableQuery,
  WaterStationGroupedTableDocument,
  WaterStationGroupedTableQuery,
  WaterStationGroupedTableQueryVariables,
} from '@/graphql';
import { useSuspenseQuery } from '@apollo/client';
import { WaterStationDataTable } from '@/components/WaterStations/WaterStationDataTable';
import { WaterStationFilterPanel } from '@/components/WaterStations/WaterStationFilterPanel';
import { useWaterStationFilterSettings } from '@/components/WaterStations/WaterStationFilterSettingsContext';
import { useDocumentTitle } from '@mantine/hooks';

const UngroupedTable = () => {
  const { data, loading } = useWaterStationDataTableQuery({
    variables: {},
  });
  return (
    <WaterStationDataTable
      items={data?.waterStations?.nodes ?? []}
      fetching={loading}
      totalCount={data?.waterStations?.totalCount ?? 0}
      totalLitres={data?.waterStations?.usageSummary?.allTime}
    />
  );
};

const GroupedTable = ({ tag }: { tag: string }) => {
  const { data } = useSuspenseQuery<
    WaterStationGroupedTableQuery,
    WaterStationGroupedTableQueryVariables
  >(WaterStationGroupedTableDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      tag: tag,
    },
  });
  return <WaterStationGroupTable data={data} />;
};

function Home() {
  useDocumentTitle('Dashboard | Meet PAT');
  const { grouping } = useWaterStationFilterSettings();
  return (
    <Flex direction={'column'} gap={'md'} p={0}>
      <WaterStationFilterPanel title={<Title>All Water Stations</Title>} />
      {!grouping && <UngroupedTable />}
      {grouping && <GroupedTable tag={grouping} />}
    </Flex>
  );
}

export default withAuthenticationRequired(Home);
