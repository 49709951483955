import {
  WaterStationGroupedTableQuery,
  WaterStationGroupItemFragment,
} from '@/graphql';
import { DataTable, DataTableColumn } from 'mantine-datatable';
import { WaterStationDataTable } from '@/components/WaterStations/WaterStationDataTable';
import { useEffect, useState } from 'react';
import { Image, Text } from '@mantine/core';

type WaterStationGroupTableProps = {
  data: WaterStationGroupedTableQuery;
};

const columns: DataTableColumn<WaterStationGroupItemFragment>[] = [
  {
    accessor: '__typename',
    render: (record) => {
      switch (record.__typename) {
        case 'TaggedWaterStationGroup':
          return <Text size={'xl'}>{record.tag}</Text>;
        default:
          return (
            <Text size={'xl'} color={'dimmed'}>
              Other
            </Text>
          );
      }
    },
  },
];

export const WaterStationGroupTable = ({
  data,
}: WaterStationGroupTableProps) => {
  const [_, setExpandedRows] = useState<string[]>([]);

  useEffect(() => {
    setExpandedRows(
      data?.waterStationsByTag?.nodes?.map((r) => r.__typename) ?? []
    );
  }, [data?.waterStationsByTag?.nodes]);

  return (
    <DataTable
      columns={columns}
      minHeight={150}
      noRecordsText={'No water stations found.'}
      noRecordsIcon={
        <Image
          src={'/images/waterstation-placeholder-sm.png'}
          fit={'contain'}
          width={100}
          height={100}
          alt={'not found'}
        />
      }
      idAccessor={(record) =>
        record.__typename === 'TaggedWaterStationGroup' ? record.tag : 'unknown'
      }
      noHeader
      records={data.waterStationsByTag?.nodes ?? []}
      rowExpansion={{
        allowMultiple: true,
        trigger: 'always',
        content: (record) => {
          if (
            record.record.waterStations?.nodes == undefined ||
            record.record.waterStations?.nodes.length == 0
          ) {
            return <></>;
          }
          return (
            <WaterStationDataTable
              items={record.record.waterStations?.nodes ?? []}
              fetching={false}
              totalCount={record.record.waterStations?.totalCount}
              totalLitres={record.record.waterStations?.usageSummary?.allTime}
            />
          );
        },
      }}
    />
  );
};
