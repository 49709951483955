import {DataTable, DataTableColumn} from 'mantine-datatable';
import {WaterStationDataTableItemFragment} from '@/graphql';
import {Flex, Image, Skeleton, Stack, Text} from '@mantine/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBottleWater} from '@fortawesome/sharp-light-svg-icons';
import {useRouter} from 'next/navigation';
import {differenceInDays, formatRelative, intervalToDuration} from 'date-fns';

type WaterStationDataTableProps = {
  items: WaterStationDataTableItemFragment[] | undefined;
  totalCount: number;
  totalLitres?: number;
  fetching: boolean;
};

export const WaterStationDataTable = ({
                                        items,
                                        fetching,
                                        totalLitres,
                                        totalCount,
                                      }: WaterStationDataTableProps) => {
  const router = useRouter();
  const displayTime = (last: string | null): string => {
    if (!last) return "No Connection";
    const diff = differenceInDays(new Date(), new Date(last))
    if (diff > 14) {
      return "No Connection"
    }
    return formatRelative(new Date(last), new Date())
  }

  const columns: DataTableColumn<WaterStationDataTableItemFragment>[] = [
    {
      accessor: 'imageHref',
      title: (
        <Text size={'xl'} color={'blue'}>
          {totalCount} Water Stations
        </Text>
      ),
      width: 6,
      render: (record) => (
        <Stack>
          <Flex align={'center'}>
            <Image
              alt={record.name}
              fit={'contain'}
              width={64}
              height={64}
              radius={'lg'}
              src={
                record.imageHref ?? '/images/waterstation-placeholder-sm.png'
              }
            />
            <Flex
              gap={0}
              align={'start'}
              justify={'start'}
              direction={'column'}
            >
              <Text size={record.name.length < 30 ? 'lg' : 'md'}>
                {record.name}
              </Text>
              <Text color={'dimmed'}>{record.serial}</Text>
            </Flex>
          </Flex>
        </Stack>
      ),
    },
    {
      accessor: 'usage.last',
      title: <></>,
      visibleMediaQuery: (theme) => `(min-width: ${theme.breakpoints.md})`,
      width: 1,
      render: (record) => (
        <Skeleton visible={record.usage === undefined}>
          {!record.usage?.last && <Text size={'md'}>Never</Text>}
          {record.usage?.last && (
            <Text size={'md'}>
              {displayTime(record.usage?.last)}
            </Text>
          )}
        </Skeleton>
      ),
    },
    {
      accessor: 'usage.allTime',
      title: (
        <Skeleton visible={totalLitres === undefined}>
          <Text size={'xl'} color={'blue'}>
            {Intl.NumberFormat('en-GB', {
              style: 'unit',
              unit: 'liter',
              unitDisplay: 'long',
              maximumFractionDigits: 1,
            }).format(totalLitres ?? 0)}
          </Text>
        </Skeleton>
      ),
      width: 1,
      render: (record) => (
        <Skeleton visible={record.usage?.allTime === undefined}>
          <Text size={'md'}>
            {Intl.NumberFormat('en-GB', {
              style: 'unit',
              unit: 'liter',
              unitDisplay: 'long',
              maximumFractionDigits: 1,
            }).format(record.usage?.allTime ?? 0)}
          </Text>
        </Skeleton>
      ),
    },
    {
      accessor: 'usage.bottles',
      visibleMediaQuery: (theme) => `(min-width: ${theme.breakpoints.md})`,
      title: (
        <Skeleton visible={totalLitres === undefined}>
          <Flex gap={'xs'} align={'center'}>
            <Text color={'dimmed'} size={'md'}>
              <FontAwesomeIcon icon={faBottleWater} size={'xl'}/>
            </Text>
            <Text size={'xl'} color={'blue'}>
              {Intl.NumberFormat('en-GB', {
                maximumFractionDigits: 0,
              }).format((totalLitres ?? 0) / 0.6)}{' '}
              Bottles
            </Text>
          </Flex>
        </Skeleton>
      ),
      width: 1,
      render: (record) => (
        <Skeleton visible={record.usage?.allTime === undefined}>
          <Flex gap={'xs'} align={'center'}>
            <Text color={'dimmed'} size={'md'}>
              <FontAwesomeIcon icon={faBottleWater} size={'xl'}/>
            </Text>
            <Text size={'md'}>
              {Intl.NumberFormat('en-GB', {
                maximumFractionDigits: 0,
              }).format((record.usage?.allTime ?? 0) / 0.6)}
            </Text>
          </Flex>
        </Skeleton>
      ),
    },
  ];

  return (
    <DataTable
      minHeight={150}
      noRecordsText={'No water stations.'}
      noRecordsIcon={
        <Image
          src={'/images/waterstation-placeholder-sm.png'}
          fit={'contain'}
          width={100}
          height={100}
          alt={'not found'}
        />
      }
      columns={columns}
      records={items}
      highlightOnHover
      fetching={fetching}
      onRowClick={(row, rowIndex, event) => {
        router.push(`/waterstations/${encodeURIComponent(row.id)}`);
      }}
    />
  );
};
